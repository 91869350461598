.container {
    background: var(--color-blue-dark);
    margin: 0 auto;
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.logo {
    width: 150px;
}

.container h1 {
    color: white;
    font-weight: 100;
    -webkit-font-smoothing: antialised;
    font-size: 20px;
    padding-bottom: 22px;
}