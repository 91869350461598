.select {
    height: 40px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
    min-width: 300px;
    padding: 0 8px;
    background: white;
    max-width: 200px;
}

.disabled {
    background: #fafafa;
    opacity: 0.4;
}

.error {
    color: #F35E5E;
    display: inline;
    position: absolute;
    top: -20px;
    font-size: 12px;
    font-weight: 600;
}