.header {
    display: flex;
    width: 100%;
    height: 60px;
    margin-bottom: 30px;
    background: var(--color-blue-dark);
}

.logo {
    text-align: left;
    align-self: center;
    padding-left: 15px;
}

.navigation {
    flex: 1;
    text-align: left;
    margin-left: 30px;
    align-self: center;
}

.logo img {
    cursor: pointer;
}

.button {
    height: 32px;
    background: var(--color-blue-dark);
    text-align: right;
    align-items: flex-end;
    align-self: center;
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid white;
    text-decoration: none !important;
    color: white !important;
    cursor: pointer !important;
}

.active {
    background: white;
    color: var(--color-blue-dark) !important;
}

.active:hover {
    background: white !important;
}

.mr15 {
    margin-right: 15px;
}

.button:hover {
    background: var(--color-blue-light);
}

.button p {
    display: inline;
    cursor: pointer;
}