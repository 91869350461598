.FileList {
    list-style-type: none;
    padding: 0;
}

.FileList li {
    margin-top: 8px;
}

.DeleteButton {
    border: 0;
    background: transparent;
    cursor: pointer;
    color: red;
    margin-top: -2px;
}

.UploadButton {
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
}

.UploadButton:hover {
    background: #d7d7d7;
}