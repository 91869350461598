.container {
    display: flex;
    margin: 45px 0;
}

.label {
    width: 300px;
    font-family: 'SF UI Text Semibold', sans-serif;
}

.subLabel {
    padding-top: 6px;
    font-size: 12px;
    font-family: 'SF UI Text Light', sans-serif;
}

.content {
    flex: 1;
    flex-grow: 1
}