.button {
    padding: 12px;
    border: 0;
    background: #98ACB8;
    border-radius: 3px;
    color: white;
    font-family: 'SF UI Text Medium', sans-serif;
    letter-spacing: 0.4px;
    position: relative;
    cursor: pointer;
}

.button:hover {
    filter: brightness(92%);
}

.primary {
    background: #0096EB;
}

.icon {
    padding-right: 27px;
}

.icon:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
}

.box:before {
    content: url('./icons/box.svg');
}

.arrowDown:before {
    content: url('./icons/arrow_down.svg');
}

.arrowDownBox:before {
    content: url('./icons/arrow_down_box.svg');
}

.arrowUpBox:before {
    content: url('./icons/arrow_up_box.svg');
}

.cross:before {
    content: url('./icons/cross.svg');
}