.ul {
    list-style: none;
    padding-left: 0;
}

.ul li {
    display: inline;
}

.ul li+li:before {
    padding: 8px;
    color: var(--color-black);
    opacity: 0.4;
    font-family: 'SF UI Text Medium', sans-serif;
    content: "/\00a0";
}

.rootButton {
    padding: 0;
    text-decoration: underline;
}

.activeLabel {
    font-family: 'SF UI Text Bold', sans-serif;
    color: var(--color-blue-dark);
}