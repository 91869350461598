.List {
    list-style-type: none;
    padding: 0;
}

.List li {
    margin-top: 8px;
}

.DeleteButton {
    border: 0;
    background: transparent;
    cursor: pointer;
    color: #868686;
    margin-top: -2px;
}

.AddButton {
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
}

.AddButton:hover {
    background: #d7d7d7;
}