.input {
    height: 40px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
    min-width: 300px;
    padding: 0 8px;
}

.textarea {
    height: auto;
    padding: 8px;
}

.error {
    color: #F35E5E;
    display: inline;
    position: absolute;
    top: -30px;
    font-size: 12px;
    font-weight: 600;
}