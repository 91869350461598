.container {
    padding: 9px 10px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
    /* margin-right: 5px; */
    margin-left: 10px;
    background: #F8F8F8;
    position: sticky;
    top: 10px;
    margin-bottom: 10px;
    background: white;
}

.buttons {
    margin-top: 30px;
    text-align: right;
}

.reset {
    background: #545454;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    position: absolute;
    top: -10px;
    left: -10px;
    cursor: pointer;
}

.grid {
    display: grid;
    grid-template-columns: 50px 60px auto;
    grid-template-rows: 30px auto;
}

.grid > span > strong {
    font-size: 12px;
    color: #8e8e93;
    padding-bottom: 100px;
}

.grid > span {
    /* padding: 8px 4px; */
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}