.flatpickr-input {
    height: 40px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
    min-width: 300px;
    padding: 0 8px;
    cursor: pointer;
}

.flatpickr-error {
    color: #F35E5E;
    display: inline;
    font-size: 12px;
    font-weight: 600;
    margin-left: 12px;
}