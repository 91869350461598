@import '_fonts.css';
@import '_vars.css';
body {
    background: #FDFDFD;
    font-size: 14px;
    font-family: 'SF UI Text Regular', sans-serif;
    color: var(--color-black);
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.5px;
}

.global-container {
    max-width: var(--width-container-max);
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

input:focus, textarea:focus, select:focus, button:focus{
    outline: none;
}

.rs-table {
    border: 1px solid #E3E3E3;
    border-radius: 3px;
}