.container {
    margin-top: 30px;
    margin: 20px 0 10px 0;
    z-index: 9999;
    /* border: 1px solid #E3E3E3; */
    border-radius: 3px;
    /* padding: 12px; */
    /* box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20); */
}

.search {
    text-align: left;
}