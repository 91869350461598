.container {
    display: flex;
    margin: 50px 0;
}

.img {
    margin-right: 45px;
    align-self: flex-end;
}

.description {
    max-width: 800px;
}

.description h1 {
    font-family: 'SF UI Text Light', sans-serif;
    font-weight: 100;
    color: var(--color-blue-dark);
}

.description p {
    font-size: 15px;
    line-height: 1.6;
}