.container {
    margin-top: 80px;
}

.content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.content h1 {
    padding-top: 80px;
    font-family: 'SF UI Text Medium', sans-serif;
    -webkit-font-smoothing: antialised;
    font-weight: 100;
}

.content p {
    font-size: 15px;
    line-height: 1.6;
}