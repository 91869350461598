
@font-face {
    font-family: 'SF UI Text Regular';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Regular'), url('./fonts/SFUIText-Regular.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Italic'), url('./fonts/SFUIText-RegularItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Light';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Light'), url('./fonts/SFUIText-Light.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Light Italic'), url('./fonts/SFUIText-LightItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Medium'), url('./fonts/SFUIText-Medium.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Medium Italic'), url('./fonts/SFUIText-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Semibold'), url('./fonts/SFUIText-Semibold.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Semibold Italic'), url('./fonts/SFUIText-SemiboldItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Bold';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Bold'), url('./fonts/SFUIText-Bold.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Bold Italic'), url('./fonts/SFUIText-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Heavy'), url('./fonts/SFUIText-Heavy.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Heavy Italic'), url('./fonts/SFUIText-HeavyItalic.woff') format('woff');
}